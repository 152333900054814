'use client';

import { Amplify } from 'aws-amplify';
import { Open_Sans } from 'next/font/google';
import {
  SettingsConsumer,
  SettingsProvider,
} from '@mui-devias-kit/context/settings';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui-devias-kit/theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { Toaster } from '@mui-devias-kit/components/toaster';
import {
  AmplifyAuthContextProvider,
  UserDataContextProvider,
} from '@/contexts';
import config from '@/config';
import { FroloApiReadProvider } from '@/api';

const openSans = Open_Sans({
  subsets: ['latin'],
  // default, can also use "swap" to ensure custom font always shows
  display: 'optional',
});

Amplify.configure(config.awsConfig);

const resetSettings = (): void => {
  try {
    //TODO Settings
    window.location.reload();
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

const updateSettings = (): void => {
  try {
    //TODO Settings
    window.location.reload();
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <html lang="en">
      <body style={{ margin: 0, padding: 0 }} className={openSans.className}>
        <AmplifyAuthContextProvider>
          <FroloApiReadProvider>
            <UserDataContextProvider>
              <SettingsProvider
                onReset={resetSettings}
                onUpdate={updateSettings}
                settings={undefined}
              >
                <SettingsConsumer>
                  {(settings) => {
                    const theme = createTheme({
                      colorPreset: settings.colorPreset,
                      contrast: settings.contrast,
                      direction: settings.direction,
                      paletteMode: settings.paletteMode,
                      responsiveFontSizes: settings.responsiveFontSizes,
                    });
                    return (
                      <ThemeProvider theme={theme}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          {children}
                        </LocalizationProvider>

                        <Toaster />
                      </ThemeProvider>
                    );
                  }}
                </SettingsConsumer>
              </SettingsProvider>
            </UserDataContextProvider>
          </FroloApiReadProvider>
        </AmplifyAuthContextProvider>
      </body>
    </html>
  );
}
